import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";

const EnquiryForm = (props) => {
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [custMessage, setCustMessage] = useState("");

  const [errors, setErrors] = useState({
    custName: "",
    custEmail: "",
    custContact: "",
    custSubject: "",
    custMessage: "",
  });

  const handleValidation = () => {
    let isValid = true;
    const newErrors = {
      custName: "",
      custEmail: "",
      custContact: "",
      custSubject: "",
      custMessage: "",
    };

    if (!custName.trim()) {
      isValid = false;
      newErrors.custName = "Name is required";
    }

    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!custEmail.match(emailRegex)) {
      isValid = false;
      newErrors.custEmail = "Email is not valid";
    }

    if (!custContact.trim()) {
      isValid = false;
      newErrors.custContact = "Phone is required";
    } else if (!/^\d{10}$/.test(custContact)) {
      isValid = false;
      newErrors.custContact = "Phone must have 10 digits";
    }
    
    if (!custSubject.trim()) {
      isValid = false;
      newErrors.custSubject = "Subject is required";
    }
    if (!custMessage.trim()) {
      isValid = false;
      newErrors.custMessage = "Write a Message";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (handleValidation()) {
      var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#07b7ff">Jai Santoshi Furniture Works Enquiry</h2><p>Hello Jai Santoshi Furniture Works,</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#07b7ff">' + custName + '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#07b7ff">' + custEmail + '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#07b7ff">' + custContact + '</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left;color:#07b7ff">' + custSubject + '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#07b7ff">' + custMessage + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#07b7ff">Jai Santoshi Furniture Works</span>.</p></div></body></html>';
      $.post(
        "https://skdm.in/server/v1/send_lead_mail.php",
        {
          toEmail: "jsfwl95@gmail.com",
          fromEmail: "skdmlead@gmail.com",
          bccMail: "skdmlead@gmail.com",
          mailSubject: "New Customer Lead",
          mailBody: body,
          accountName: "Jai Santoshi",
          accountLeadSource: "https://skdm.in/",
          accountLeadName: custName,
          // accountLeadEmail: custEmail,
        },
        function (dataa, status) {
          console.log("data :" + dataa);
        }
      );
  
      alert("Your Form has Submitted Our team will contact with You  soon.");
      e.preventDefault();
      return false;
      props.onHide();
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Enquiry Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                <div class="single-input-field">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                    value={custName}
                      onChange={(e) => setCustName(e.target.value)}
                  />{errors.custName && (
                    <span className="error-text">{errors.custName}</span>
                  )}

                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                <div class="single-input-field">
                  <input
                    className="form-control"
                    type="email"
                    placeholder="E-mail"
                    value={custEmail}
                      onChange={(e) => setCustEmail(e.target.value)}
                  />
                 {errors.custEmail && (
                    <span className="error-text">{errors.custEmail}</span>
                  )}
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                <div class="single-input-field">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Phone Number"
                    value={custContact}
                    onChange={(e) => setCustContact(e.target.value)}
                  />
                 {errors.custContact && (
                    <span className="error-text">{errors.custContact}</span>
                  )}
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 mt-3">
                <div class="single-input-field">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Subject"
                    value={custSubject}
                    onChange={(e) => setCustSubject(e.target.value)}
                  />
                  {errors.custSubject && (
                    <span className="error-text">{errors.custSubject}</span>
                  )}
                </div>
              </div>
              <div class="col-md-12 message-input mt-3">
                <div class="single-input-field">
                  <textarea
                    placeholder="Write Your Message"
                    className="form-control"
                    cols="30"
                    rows="4"
                    value={custMessage}
                  onChange={(e) => setCustMessage(e.target.value)}
                  ></textarea>
                  {errors.custMessage && (
                    <span className="error-text">{errors.custMessage}</span>
                  )}
                </div>
              </div>
              <div class="single-input-fieldsbtn">
                <div class="contactForm mt-4">
                  <button  className="modal-btn" onClick={handleSubmit}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EnquiryForm;
