import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Pagination } from "swiper/modules";
import "../Css/About.css";
import Footer from "../Components/Footer";
import CountUp from "react-countup";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";
import "../Css/About.css";
import Button from "react-bootstrap/Button";
import EnquiryModal from "../Components/EnquiryForm";
import Company from "../img/commercial/kohinoor2.jpg";
import AboutImg from "../img/baanner/banner1.jpg";

const About = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
      <meta name="google-site-verification" content="gRPdG25aiiApMhOHHkpeE6KdvdyjTSPKFESzByKLyHA" />
        <title>Civil Contractor in Borivali | Jai Santoshi</title>
        <meta
          name="description"
          content=" Looking for Civil Contractors in Borivali? Our expert builders provide top-notch construction and renovation services. Contact us for quality workmanship today!"
        />
        <meta
          property="og:title"
          content="Civil Contractor in Borivali | Jai Santoshi"
        />
        <meta
          property="og:description"
          content=" Looking for Civil Contractors in Borivali? Our expert builders provide top-notch construction and renovation services. Contact us for quality workmanship today!"
        />
        <meta name="keywords" content="Civil Contractor in Borivali" />
        <link rel="canonical" href="jaisantoshifurnitureworks.in/civil-contractor-borivali" />
       
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ========== About Banner ==== */}
      <section className="about-banner">
        <div className="container">
          <div className="about-Txt">
            <h1 className="d-none">Civil Contractor in Borivali</h1>
            <h2>About Us</h2>
            <ul className="page-breadcrumb">
              <li>
                <Link to="/">
                  <i className="fa fa-home" /> Home
                </Link>{" "}
                <i className="fa fa-angle-double-right" />
              </li>
              <li>
                <Link to="/civil-contractor-borivali">pages</Link>
                <i className="fa fa-angle-double-right" />
              </li>
              <li>
                <span>About</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* =========== Enquiry Button ========== */}
      <section className="action-box small yellow-bg">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-8 text-start">
              <h4 className="m-0">
                Jai Santoshi Furniture Works Contractors &amp; Construction
              </h4>
            </div>
            <div className="col-md-4 text-start text-md-end">
              <div className="Enquiry">
                <div className="modal-btn" onClick={() => setModalShow(true)}>
                  Submit Enquiry
                </div>

                <EnquiryModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* =========== About Services ========== */}
      <section className="our-sercive page-section-ptb">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <img
                className="img-fluid"
                src={AboutImg}
                alt=" about jai santoshi"
              />
            </div>
            <div className="col-lg-6">
              <div className="content-block pl-10">
                <div className="section-title">
                  <h2 className="title">About Us</h2>
                </div>
                <p>
                  Welcome to the Jai santoshi furniture works, a civil
                  contractor in borivali . Our work focuses on luxury interiors
                  and architecture. We take part in many projects, from home
                  projects to office and hotel projects. Jai Santoshi furniture
                  works has experience in turning your design dreams into
                  reality. Interior design is the art and science of improving
                  the interior of a building to create a healthy and beautiful
                  environment for the people who use the space.{" "}
                </p>
                <p>
                  All our products and services are recognized by our customers
                  for their durability, beautiful designs, beautiful appearance,
                  flawless surfaces and smooth edges. These products are
                  available in various designs, shapes and sizes to meet the
                  diverse needs of customers
                </p>
                {/* <div className="row mt-30">
                  <div className="col-sm-6 mb-4 mb-sm-0">
                    <div className="feature-box left_bor left_pos ex-small mb-0">
                    <i class="fa-solid fa-building"></i>
                      <h3 className="title">Good Sale &amp; Marketing</h3>
                      <p>
                        Eiusmod tempor incididunt ut labore et dolore Ut enim ad
                        minim veniam quis
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="feature-box left_bor left_pos ex-small mb-0">
                    <i class="fas fa-chart-bar"></i>
                      <h3 className="title">Virtual Construction (BIM)</h3>
                      <p>
                        It has survived not only five centuries, but also the
                        leap into electronic
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========== About Company ========== */}
      <section
        className="dark-bg page-section-ptb bg fixed text-white gradient-overlay counter-section mb-5"
        style={{
          backgroundImage: `url(${Company})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <div className="section-title">
                <h2 className="title title2">
                  The Best <label>Compnay</label> Ever
                </h2>
              </div>
              <p>
                Jai Santoshi Construction is renowned for its unwavering
                commitment to quality and excellence in the construction
                industry, making it the best choice for all your building needs.
              </p>
              <Link
                to="/flooring-contractor-borivali-mumbai"
                className="modal-btn1"
              >
                <span>Read More</span>
              </Link>
            </div>
            <div className="col-md-6">
              <div className="row counter-block pt-20">
                <div className="col-6 mb-40">
                  <div className="counter left_icn">
                    <i class="fas fa-chart-bar"></i>
                    <span className="timer">
                      <CountUp end={1520} duration={5} />
                    </span>
                    <label>Projects</label>
                  </div>
                </div>
                <div className="col-6 mb-40">
                  <div className="counter left_icn">
                    <i class="fa fa-tasks" aria-hidden="true"></i>
                    <span className="timer">
                      <CountUp end={900} duration={5} />
                    </span>
                    <label>Done</label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="counter left_icn mb-3 mb-sm-0">
                    <i class="fa-solid fa-award"></i>
                    <span className="timer">
                      <CountUp end={3500} duration={5} />
                    </span>
                    <label>Award</label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="counter left_icn mb-0">
                    <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                    <span className="timer">
                      <CountUp end={2100} duration={5} />
                    </span>
                    <label>Review</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============== About Partner =========== */}
      {/* <section className="page-section-ptb4">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title text-center">
                <h2 className="title">Our Partner</h2>
                <p>
                  Jai Santoshi Construction and its trusted partners form an
                  unshakeable alliance, dedicated to delivering top-notch
                  construction solutions with unwavering precision and
                  integrity.
                </p>
              </div>
            </div>
            <Swiper
              slidesPerView={1}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                450: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
              }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="our-clients">
                  <div className="item">
                    <a href="jaisantoshifurnitureworks.in/">
                      <img
                        className="img-fluid center-block"
                        src="http://themes.potenzaglobalsolutions.com/html/constro/images/clients/01.png"
                        alt="partner"
                      />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-clients">
                  <div className="item">
                    <a href="jaisantoshifurnitureworks.in/">
                      <img
                        className="img-fluid center-block"
                        src="http://themes.potenzaglobalsolutions.com/html/constro/images/clients/02.png"
                        alt="partner"
                      />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-clients">
                  <div className="item">
                    <a href="jaisantoshifurnitureworks.in/">
                      <img
                        className="img-fluid center-block"
                        src="http://themes.potenzaglobalsolutions.com/html/constro/images/clients/03.png"
                        alt="partner"
                      />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-clients">
                  <div className="item">
                    <a href="jaisantoshifurnitureworks.in/">
                      <img
                        className="img-fluid center-block"
                        src="http://themes.potenzaglobalsolutions.com/html/constro/images/clients/04.png"
                        alt="partner"
                      />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-clients">
                  <div className="item">
                    <a href="jaisantoshifurnitureworks.in/">
                      <img
                        className="img-fluid center-block"
                        src="http://themes.potenzaglobalsolutions.com/html/constro/images/clients/05.png"
                        alt="partner"
                      />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="our-clients">
                  <div className="item">
                    <a href="jaisantoshifurnitureworks.in/">
                      <img
                        className="img-fluid center-block"
                        src="http://themes.potenzaglobalsolutions.com/html/constro/images/clients/06.png"
                        alt="partner"
                      />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section> */}
      <Footer />
    </>
  );
};

export default About;
