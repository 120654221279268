import Services1 from "../img/logo.jpeg";

const PropertyData = [
  {
    id: 1,
    Slugs: "/carpenters",
    isDefault: true,
    Header: "Carpenters",
    ServicesImage:Services1,
    SercicesHeading1:"Carpenters",
    SercicesHeading2:"Service Features",
    SercicesPara1:"When you start building or renovating a home, you need to create a plan to organize the space with design and quality. This means planning out the furniture you want in each room, their functions (single-purpose or multi-purpose), and specific patterns to follow. If you are not a keen DIYer, professional advice in the form of an interior designer is recommended. Therefore, interior designers can consider how to model or modify the space according to their preferences and needs.",
    SercicesPara2:"They can use the latest technology to show you how your site will look and be finished in products before work begins.our carpenter helps you to get the best option for your home",
    headingPoint1:"Thematic Decor:",
    headingPoint2:"Culinary Delights:",
    headingPoint3:"Tech Support:",
    headingPoint4:"Convenient Parking:",
    headingPoint5:"Efficient Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators specialize in creating immersive thematic decorations that set the perfect mood for your kitty party, whether it's a retro theme, a Bollywood night, or a garden soiree.",
    ServicesPoint2:"Our in-house culinary experts prepare a delightful range of dishes, from finger foods to exquisite desserts, ensuring that your guests savor every bite.",
    ServicesPoint3:"Advanced audio-visual equipment is available for entertainment, including music systems and screens for games and activities.",
    ServicesPoint4:"Ample parking space is provided to ensure the comfort of your guests.",
    ServicesPoint5:" Our experienced staff in Goregaon takes care of every detail, from setup to cleanup, allowing you to focus on enjoying your kitty party.",
    ServicesPoint6:" ",
  },
  {
    id: 2,
    Slugs: "/civil-contractots",
    isDefault: false,
    Header:"Civil Contractors",
    ServicesImage:Services1,
    SercicesHeading1:"Civil Contractors",
    SercicesHeading2:"Service Features",
    SercicesPara1:"We specialize in all types of construction work for our clients. We have the right to serve many famous brands in the market. We offer proven construction plans for all residential and commercial needs.",
    SercicesPara2:"We offer the best solutions that present beauty in the best way in the company's interiors, attract the attention of the same people and leave surprises in the mind. We specialize in turning your ideas into reality. Whether you are decorating the interior of a small room or beautifying the interior of a conference or office, you can trust us. Our team of interior designers have many years of experience in interior design and will work according to the client's ideas, needs and designs. We strive to inspire people's hearts, minds and interests through the unparalleled success of customer plans.",
    headingPoint1:"Thematic Decor:",
    headingPoint2:"Culinary Delights:",
    headingPoint3:"Tech Support:",
    headingPoint4:"Convenient Parking:",
    headingPoint5:"Efficient Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators specialize in creating immersive thematic decorations that set the perfect mood for your kitty party, whether it's a retro theme, a Bollywood night, or a garden soiree.",
    ServicesPoint2:"Our in-house culinary experts prepare a delightful range of dishes, from finger foods to exquisite desserts, ensuring that your guests savor every bite.",
    ServicesPoint3:"Advanced audio-visual equipment is available for entertainment, including music systems and screens for games and activities.",
    ServicesPoint4:"Ample parking space is provided to ensure the comfort of your guests.",
    ServicesPoint5:" Our experienced staff in Goregaon takes care of every detail, from setup to cleanup, allowing you to focus on enjoying your kitty party.",
    ServicesPoint6:" ",
  },
  {
    id: 3,
    Slugs: "/contractots",
    isDefault: false,
    Header:"Contractors",
    ServicesImage:Services1,
    SercicesHeading1:"Contractors",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Jai Santoshi Furniture Works is a trusted name in the world of furniture craftsmanship. With a passion for excellence and an unwavering commitment to delivering top-notch, customized furniture solutions, they bring your interior design dreams to life. Their skilled artisans craft pieces that harmonize style and functionality, ensuring your living spaces are adorned with the finest in quality and aesthetics.",
    SercicesPara2:"Jai Santoshi Furniture Works is a reputable contractor known for their exceptional craftsmanship and dedication to providing high-quality, custom furniture solutions. With a keen eye for design and a commitment to customer satisfaction, they specialize in crafting unique pieces that seamlessly blend style and functionality to elevate any living space.",
    headingPoint1:"Thematic Decor:",
    headingPoint2:"Culinary Delights:",
    headingPoint3:"Tech Support:",
    headingPoint4:"Convenient Parking:",
    headingPoint5:"Efficient Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators specialize in creating immersive thematic decorations that set the perfect mood for your kitty party, whether it's a retro theme, a Bollywood night, or a garden soiree.",
    ServicesPoint2:"Our in-house culinary experts prepare a delightful range of dishes, from finger foods to exquisite desserts, ensuring that your guests savor every bite.",
    ServicesPoint3:"Advanced audio-visual equipment is available for entertainment, including music systems and screens for games and activities.",
    ServicesPoint4:"Ample parking space is provided to ensure the comfort of your guests.",
    ServicesPoint5:" Our experienced staff in Goregaon takes care of every detail, from setup to cleanup, allowing you to focus on enjoying your kitty party.",
    ServicesPoint6:" ",
  },
  {
    id: 4,
    Slugs: "/electrical-contractots",
    isDefault: false,
    Header:"Electrical Contractors",
    ServicesImage:Services1,
    SercicesHeading1:"Electrical Contractors",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Jai Santoshi furniture works have professional electrical interior design experts with years of experience in the design, installation and maintenance of electrical systems. There are many places that attract us with their warmth, and all due to good lighting. Therefore, all business owners and residents need to pay attention to electrical work in their offices or homes. The light chosen should match the size of the room and the color of the wall to provide bright and pleasant light.",
    SercicesPara2:"We understand all these facts, and our interior lighting experts will help you bring the best lighting to your space.",
    headingPoint1:"Thematic Decor:",
    headingPoint2:"Culinary Delights:",
    headingPoint3:"Tech Support:",
    headingPoint4:"Convenient Parking:",
    headingPoint5:"Efficient Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators specialize in creating immersive thematic decorations that set the perfect mood for your kitty party, whether it's a retro theme, a Bollywood night, or a garden soiree.",
    ServicesPoint2:"Our in-house culinary experts prepare a delightful range of dishes, from finger foods to exquisite desserts, ensuring that your guests savor every bite.",
    ServicesPoint3:"Advanced audio-visual equipment is available for entertainment, including music systems and screens for games and activities.",
    ServicesPoint4:"Ample parking space is provided to ensure the comfort of your guests.",
    ServicesPoint5:" Our experienced staff in Goregaon takes care of every detail, from setup to cleanup, allowing you to focus on enjoying your kitty party.",
    ServicesPoint6:" ",
  },
  {
    id: 5,
    Slugs: "/ceiling-contractots",
    isDefault: false,
    Header:"Ceiling Contractors",
    ServicesImage:Services1,
    SercicesHeading1:"Ceiling Contractors",
    SercicesHeading2:"Service Features",
    SercicesPara1:"We provide you our best ceiling contractor for your dream project. The ceiling is false and gives the illusion of a real ceiling. This will not only help make the room more beautiful, but also help control the temperature in the room or make the air conditioner work more efficiently",
    SercicesPara2:" This is one of the first features of interior design and the basis of interior design and decoration services. In addition, in combination with lighting, suspended ceilings can enhance the beauty of the room and attract guests.",
    headingPoint1:"Thematic Decor:",
    headingPoint2:"Culinary Delights:",
    headingPoint3:"Tech Support:",
    headingPoint4:"Convenient Parking:",
    headingPoint5:"Efficient Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators specialize in creating immersive thematic decorations that set the perfect mood for your kitty party, whether it's a retro theme, a Bollywood night, or a garden soiree.",
    ServicesPoint2:"Our in-house culinary experts prepare a delightful range of dishes, from finger foods to exquisite desserts, ensuring that your guests savor every bite.",
    ServicesPoint3:"Advanced audio-visual equipment is available for entertainment, including music systems and screens for games and activities.",
    ServicesPoint4:"Ample parking space is provided to ensure the comfort of your guests.",
    ServicesPoint5:" Our experienced staff in Goregaon takes care of every detail, from setup to cleanup, allowing you to focus on enjoying your kitty party.",
    ServicesPoint6:" ",
  },
  {
    id: 6,
    Slugs: "/flooring-contractots",
    isDefault: false,
    Header:"Flooring Contractors",
    ServicesImage:Services1,
    SercicesHeading1:"Flooring Contractors",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Renew the style of your home or business with the help of jai santoshi furniture works. Our design consultants are ready to help you explore the world of interior design. Choosing the right materials, styles and colors for your home or business can be stressful, but it doesn't have to be with our team.",
    SercicesPara2:"We apply our interior design skills to different living and working areas of your life, such as residences, commercial areas, kitchens and bathrooms. At Jai Santoshi furniture works in Borivali, you can rely on our years of experience to guide you through your next home improvement project.",
    headingPoint1:"Thematic Decor:",
    headingPoint2:"Culinary Delights:",
    headingPoint3:"Tech Support:",
    headingPoint4:"Convenient Parking:",
    headingPoint5:"Efficient Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators specialize in creating immersive thematic decorations that set the perfect mood for your kitty party, whether it's a retro theme, a Bollywood night, or a garden soiree.",
    ServicesPoint2:"Our in-house culinary experts prepare a delightful range of dishes, from finger foods to exquisite desserts, ensuring that your guests savor every bite.",
    ServicesPoint3:"Advanced audio-visual equipment is available for entertainment, including music systems and screens for games and activities.",
    ServicesPoint4:"Ample parking space is provided to ensure the comfort of your guests.",
    ServicesPoint5:" Our experienced staff in Goregaon takes care of every detail, from setup to cleanup, allowing you to focus on enjoying your kitty party.",
    ServicesPoint6:" ",
  },
  {
    id: 7,
    Slugs: "/painting-contractots",
    isDefault: false,
    Header:"Painting Contractors",
    ServicesImage:Services1,
    SercicesHeading1:"Painting Contractors",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Our experienced staff can help you choose paint and colors, but if you are looking for more creative ideas for your home or business, we are happy to provide you with Advertising interior design services.",
    SercicesPara2:"Whether you have a bedroom in need of a makeover or you're building a new home from scratch, we'll connect you with a local architect who can help you create the space you've always dreamed of. Both residential and commercial interior design is available.",
    headingPoint1:"Thematic Decor:",
    headingPoint2:"Culinary Delights:",
    headingPoint3:"Tech Support:",
    headingPoint4:"Convenient Parking:",
    headingPoint5:"Efficient Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators specialize in creating immersive thematic decorations that set the perfect mood for your kitty party, whether it's a retro theme, a Bollywood night, or a garden soiree.",
    ServicesPoint2:"Our in-house culinary experts prepare a delightful range of dishes, from finger foods to exquisite desserts, ensuring that your guests savor every bite.",
    ServicesPoint3:"Advanced audio-visual equipment is available for entertainment, including music systems and screens for games and activities.",
    ServicesPoint4:"Ample parking space is provided to ensure the comfort of your guests.",
    ServicesPoint5:" Our experienced staff in Goregaon takes care of every detail, from setup to cleanup, allowing you to focus on enjoying your kitty party.",
    ServicesPoint6:" ",
  },
  {
    id: 8,
    Slugs: "/plumbing-contractots",
    isDefault: false,
    Header:"Plumbing Contractors",
    ServicesImage:Services1,
    SercicesHeading1:"Plumbing Contractors",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Jai Santoshi Furniture Works is a renowned name in the world of plumbing contractors, known for their expertise in creating custom plumbing solutions that seamlessly integrate with your interior design. With a dedication to precision and functionality, they offer a wide range of plumbing services that ensure your spaces are not only beautifully furnished but also equipped with efficient and reliable plumbing systems, enhancing the overall functionality of your living environment.",
    SercicesPara2:"The domain of plumbing contracting, excels in blending form and function. Their commitment to precision and aesthetics shines through as they expertly design and install plumbing systems that seamlessly harmonize with your interior spaces. With a focus on both beauty and utility, they ensure that your living environment is not only exquisitely furnished but also equipped with efficient and dependable plumbing solutions, elevating the overall functionality of your space.",
    headingPoint1:"Thematic Decor:",
    headingPoint2:"Culinary Delights:",
    headingPoint3:"Tech Support:",
    headingPoint4:"Convenient Parking:",
    headingPoint5:"Efficient Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators specialize in creating immersive thematic decorations that set the perfect mood for your kitty party, whether it's a retro theme, a Bollywood night, or a garden soiree.",
    ServicesPoint2:"Our in-house culinary experts prepare a delightful range of dishes, from finger foods to exquisite desserts, ensuring that your guests savor every bite.",
    ServicesPoint3:"Advanced audio-visual equipment is available for entertainment, including music systems and screens for games and activities.",
    ServicesPoint4:"Ample parking space is provided to ensure the comfort of your guests.",
    ServicesPoint5:" Our experienced staff in Goregaon takes care of every detail, from setup to cleanup, allowing you to focus on enjoying your kitty party.",
    ServicesPoint6:" ",
  },
  {
    id: 9,
    Slugs: "/pop-contractots",
    isDefault: false,
    Header: "Pop Contractors",
    ServicesImage:Services1,
    SercicesHeading1:"Pop Contractors",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Jai santoshi furniture works has an excellent team of designers and executioners who work together to create outstanding designs that will put every mind at ease. And that too at very reasonable prices. Our prices are the lowest on the market. We do not charge anything extra from our clients. There is one more important point that keeps us ahead of the rest – the point of excellent craftsmanship in applying the designs to the roof.",
    SercicesPara2:"Suspended ceilings, combined with the special efforts of the masters, add elegance to every ceiling of the room. Provides beautiful patterns from the roof. Beautiful colors in the design add more character. The steel frame continues to provide additional security.",
    headingPoint1:"Thematic Decor:",
    headingPoint2:"Culinary Delights:",
    headingPoint3:"Tech Support:",
    headingPoint4:"Convenient Parking:",
    headingPoint5:"Efficient Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators specialize in creating immersive thematic decorations that set the perfect mood for your kitty party, whether it's a retro theme, a Bollywood night, or a garden soiree.",
    ServicesPoint2:"Our in-house culinary experts prepare a delightful range of dishes, from finger foods to exquisite desserts, ensuring that your guests savor every bite.",
    ServicesPoint3:"Advanced audio-visual equipment is available for entertainment, including music systems and screens for games and activities.",
    ServicesPoint4:"Ample parking space is provided to ensure the comfort of your guests.",
    ServicesPoint5:" Our experienced staff in Goregaon takes care of every detail, from setup to cleanup, allowing you to focus on enjoying your kitty party.",
    ServicesPoint6:" ",
  },
  {
    id: 10,
    Slugs: "/interior-designing",
    isDefault: false,
    Header:"Interior Designing",
    ServicesImage:Services1,
    SercicesHeading1:"Interior Designing",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Interior design is the art and science of interior design with the goal of creating a healthy and positive environment. More music for people. Users of the site. Attractive homes and offices can improve life and happiness.",
    SercicesPara2:"Our interior accessories allow you to add beauty to walls, ceilings, lighting, doors and windows. Our multidisciplinary team of interior designers and professionals is dedicated to providing quality and innovative solutions for business, community, corporate, business and multifamily",
    headingPoint1:"Thematic Decor:",
    headingPoint2:"Culinary Delights:",
    headingPoint3:"Tech Support:",
    headingPoint4:"Convenient Parking:",
    headingPoint5:"Efficient Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators specialize in creating immersive thematic decorations that set the perfect mood for your kitty party, whether it's a retro theme, a Bollywood night, or a garden soiree.",
    ServicesPoint2:"Our in-house culinary experts prepare a delightful range of dishes, from finger foods to exquisite desserts, ensuring that your guests savor every bite.",
    ServicesPoint3:"Advanced audio-visual equipment is available for entertainment, including music systems and screens for games and activities.",
    ServicesPoint4:"Ample parking space is provided to ensure the comfort of your guests.",
    ServicesPoint5:" Our experienced staff in Goregaon takes care of every detail, from setup to cleanup, allowing you to focus on enjoying your kitty party.",
    ServicesPoint6:" ",
  },
];

export default PropertyData;
