// ========= Hospital Project ==========

import Hospitality_project1 from "../img/hospitality/bbq1.jpg";
import Hospitality_project2 from "../img/hospitality/bbq5.jpg";
import Hospitality_project3 from "../img/hospitality/bbq3.jpg";
import Hospitality_project4 from "../img/hospitality/bbq4.jpg";
import Hospitality_project5 from "../img/hospitality/bbq6.jpg";

// =========== Commercial Project ==========
import Commercial_Project1 from "../img/commercial/kahoor1.jpg";
import Commercial_Project2 from "../img/commercial/kohinoor2.jpg";
import Commercial_Project3 from "../img/commercial/stulz1.jpg";
import Commercial_Project4 from "../img/commercial/stulz2.jpg";

// ============ HealthCatre Project ==========
import HealthCare_Project1 from "../img/health-care/noor1.jpg";
import HealthCare_Project2 from "../img/health-care/noor3.jpg";
import HealthCare_Project3 from "../img/health-care/noor4.jpg";
import HealthCare_Project4 from "../img/health-care/surya1.jpg";

//  =========== Residental Project ===========
import Residental_Project1 from "../img/residental/jai1.jpg";
import Residental_Project2 from "../img/residental/jai2.jpg";
import Residental_Project3 from "../img/residental/sandeep1.jpg";
import Residental_Project4 from "../img/residental/sandeep2.jpg";
import Residental_Project5 from "../img/residental/nilkhant1.jpg";
import Residental_Project6 from "../img/residental/nilkhant2.jpg";

// =========== Other Project ==============
import Other_Project1 from "../img/project/10.jpg";

const GalleryData = [
  {
    id: 1,
    Category: "Hospitality Project",
    EImage: Hospitality_project1,
    ETypes: "Hospitality Project",
    EventName: "B B Q National Andheri (W)",
  },
  {
    id: 1,
    Category: "Hospitality Project",
    EImage: Hospitality_project2,
    ETypes: "Hospitality Project",
    EventName: "B B Q National Andheri (W)",
  },
  {
    id: 1,
    Category: "Hospitality Project",
    EImage: Hospitality_project3,
    ETypes: "Hospitality Project",
    EventName: "B B Q National Andheri (W)",
  },
  {
    id: 1,
    Category: "Hospitality Project",
    EImage: Hospitality_project4,
    ETypes: "Hospitality Project",
    EventName: "B B Q National Andheri (W)",
  },
  {
    id: 1,
    Category: "Hospitality Project",
    EImage: Hospitality_project5,
    ETypes: "Hospitality Project",
    EventName: "B B Q National Andheri (W)",
  },
  {
    id: 2,
    Category: "Commercial Project",
    EImage: Commercial_Project1,
    ETypes: "Commercial Project",
    EventName: "Show Office Kohinoor",
  },
  {
    id: 2,
    Category: "Commercial Project",
    EImage: Commercial_Project2,
    ETypes: "Commercial Project",
    EventName: "Show Office Kohinoor",
  },
  {
    id: 2,
    Category: "Commercial Project",
    EImage: Commercial_Project3,
    ETypes: "Commercial Project",
    EventName: "Stuilz Corporate",
  },
  {
    id: 2,
    Category: "Commercial Project",
    EImage: Commercial_Project4,
    ETypes: "Commercial Project",
    EventName: "Stuilz Corporate",
  },
  {
    id: 3,
    Category: "HealthCare Project",
    EImage: HealthCare_Project1,
    ETypes: "HealthCare Project",
    EventName: "Noor Hospital CST Mumbai",
  },
  {
    id: 3,
    Category: "HealthCare Project",
    EImage: HealthCare_Project2,
    ETypes: "HealthCare Project",
    EventName: "Noor Hospital CST Mumbai",
  },
  {
    id: 3,
    Category: "HealthCare Project",
    EImage: HealthCare_Project3,
    ETypes: "HealthCare Project",
    EventName: "Surya Hospital Santacruz",
  },
  {
    id: 3,
    Category: "HealthCare Project",
    EImage: HealthCare_Project4,
    ETypes: "HealthCare Project",
    EventName: "Surya Hospital Santacruz",
  },
  {
    id: 4,
    Category: "Residental Project",
    EImage: Residental_Project1,
    ETypes: "Residental Project",
    EventName: "Jai Dattani Residence Kandivali",
  },
  {
    id: 4,
    Category: "Residental Project",
    EImage: Residental_Project2,
    ETypes: "Residental Project",
    EventName: "Jai Dattani Residence Kandivali",
  },
  {
    id: 4,
    Category: "Residental Project",
    EImage: Residental_Project3,
    ETypes: "Residental Project",
    EventName: "Sandeep Gurvara Worli",
  },
  {
    id: 4,
    Category: "Residental Project",
    EImage: Residental_Project4,
    ETypes: "Residental Project",
    EventName: "Sandeep Gurvara Worli",
  },
  {
    id: 4,
    Category: "Residental Project",
    EImage: Residental_Project5,
    ETypes: "Residental Project",
    EventName: "Nilkanth Building Thane",
  },
  {
    id: 4,
    Category: "Residental Project",
    EImage: Residental_Project6,
    ETypes: "Residental Project",
    EventName: "Nilkanth Building Thane",
  },
//   {
//     id: 5,
//     Category: "Other Project",
//     EImage: Other_Project1,
//     ETypes: "Other Project",
//     EventName: "Other Project",
//   },
];

export default GalleryData;
