import React from "react";
import "../Css/ErrorPage.css";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import ErrorIcon from "../img/logo.jpeg";

const ErrorPage = () => {
  return (
    <>
      <section className="Error_page">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ErrorRight">
                <div className="errorImg">
                  <img src={ErrorIcon} alt="404 Error Iflux Educare" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="error_title">
                <div className="ErrorDesc">
                  <h2>
                    <span>404</span> That's An Error
                  </h2>
                  <p>
                    The requested URL /badpage was not found on this server.
                    That's all we know. Please Go To Home Page
                  </p>
                  <Link to="/">Home</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ErrorPage;
