import React, { useState } from "react";
import "../Css/Contact.css";
import Footer from "../Components/Footer";
import { BsHeadset } from "react-icons/bs";
import { BiMailSend } from "react-icons/bi";
import { BsFillPinMapFill } from "react-icons/bs";
import Zoom from "react-reveal/Zoom";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";
import $ from "jquery";
// import Box from "@mui/material/Box";
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";

const Contact = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // email
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [custMessage, setCustMessage] = useState("");

  const [errors, setErrors] = useState({
    custName: "",
    custEmail: "",
    custContact: "",
    custSubject: "",
    custMessage: "",
  });

  const handleValidation = () => {
    let isValid = true;
    const newErrors = {
      custName: "",
      custEmail: "",
      custContact: "",
      custSubject: "",
      custMessage: "",
    };

    if (!custName.trim()) {
      isValid = false;
      newErrors.custName = "Name is required";
    }

    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!custEmail.match(emailRegex)) {
      isValid = false;
      newErrors.custEmail = "Email is not valid";
    }

    if (!custContact.trim()) {
      isValid = false;
      newErrors.custContact = "Phone is required";
    } else if (!/^\d{10}$/.test(custContact)) {
      isValid = false;
      newErrors.custContact = "Phone must have 10 digits";
    }
    
    if (!custSubject.trim()) {
      isValid = false;
      newErrors.custSubject = "Subject is required";
    }
    if (!custMessage.trim()) {
      isValid = false;
      newErrors.custMessage = "Write a Message";
    }

    setErrors(newErrors);
    return isValid;
  };

  const OnSubmit = (e) => {
    if (handleValidation()) {
      var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#07b7ff">Jai Santoshi Furniture Works Enquiry</h2><p>Hello Jai Santoshi Furniture Works,</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#07b7ff">' + custName + '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#07b7ff">' + custEmail + '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#07b7ff">' + custContact + '</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left;color:#07b7ff">' + custSubject + '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#07b7ff">' + custMessage + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#07b7ff">Jai Santoshi Furniture Works</span>.</p></div></body></html>';
      $.post(
        "https://skdm.in/server/v1/send_lead_mail.php",
        {
          toEmail: "jsfwl95@gmail.com",
          fromEmail: "skdmlead@gmail.com",
          bccMail: "skdmlead@gmail.com",
          mailSubject: "New Customer Lead",
          mailBody: body,
          accountName: "Jai Santoshi",
          accountLeadSource: "https://skdm.in/",
          accountLeadName: custName,
          // accountLeadEmail: custEmail,
        },
        function (dataa, status) {
          console.log("data :" + dataa);
        }
      );
  
      alert("Your Form has Submitted Our team will contact with You  soon.");
      e.preventDefault();
      return false;
    }
  };
  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
        <title>Flooring Contractor in Borivali | Jai Santoshi</title>
        <meta
          name="description"
          content="If you're looking for a Flooring Contractor in Borivali, Then you must visit Jai Santoshi!"
        />
        <meta
          property="og:title"
          content="Flooring Contractor in Borivali | Jai Santoshi"
        />
        <meta
          property="og:description"
          content="If you're looking for a Flooring Contractor in Borivali, Then you must visit Jai Santoshi!"
        />
        <meta name="keywords" content="Flooring Contractor in Borivali" />
        <link rel="canonical" href="jaisantoshifurnitureworks.in/flooring-contractor-borivali-mumbai" />
        <meta name="google-site-verification" content="gRPdG25aiiApMhOHHkpeE6KdvdyjTSPKFESzByKLyHA" />
      </MetaTags>
      {/* ======== Seo end ======= */}

      {/* ========== Contact Banner ======== */}
      <section className="contact-banner">
        <div className="container">
          <div className="contact-Txt">
            <h1 className="d-none">Flooring Contractor in Borivali</h1>
            <h2>Contact Us</h2>
            <ul className="page-breadcrumb">
              <li>
                <Link to="/">
                  <i className="fa fa-home" /> Home
                </Link>{" "}
                <i className="fa fa-angle-double-right" />
              </li>
              <li>
                <Link to="/flooring-contractor-borivali-mumbai">pages</Link>{" "}
                <i className="fa fa-angle-double-right" />
              </li>
              <li>
                <span>Contact</span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* ========== Contact Address Section =========== */}
      <section className="contact-sec page-section-pt">
        <div className="container mb-70">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title text-center">
                <h2 className="title">Get in Touch With us</h2>
                {/* <p>
                  Sed ut perspiciatis, unde omnis iste natus error sit
                  voluptatem accusantium doloremque.
                </p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="contact-info">
                <div className="contactDetail">
                  <div className="icon-contact">
                    <BsHeadset />
                  </div>
                </div>
                <div className="contactValue">
                  <div className="text-contact">
                    <h3>Exploration Call</h3>
                  </div>
                </div>
                <div className="contact_Values">
                  <a href="tel:+91-9820141690">+91-9820141690</a>
                  <br />
                  <a href="tel:+91-9372686073">+91-9372686073</a>
                  <br />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="contact-info">
                <div className="contactDetail">
                  <div className="icon-contact">
                    <BiMailSend />
                  </div>
                </div>
                <div className="contactValue">
                  <div className="text-contact">
                    <h3>Email Info</h3>
                  </div>
                </div>
                <div className="contact_Values">
                  <a href="jsfwl95@gmail.com">jsfwl95@gmail.com</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="contact-info">
                <div className="contactDetail">
                  <div className="icon-contact">
                    <BsFillPinMapFill />
                  </div>
                </div>
                <div className="contactValue">
                  <div className="text-contact">
                    <h3>Address</h3>
                  </div>
                </div>
                <div className="contact_Values">
                  <a href="https://maps.app.goo.gl/TQ5tW6U9CE9krqa1A">
                    Ganesh Chs Ltd Shop No1 ,2,Opp Pillai School,near Ajra
                    Bank,Gorai Road, Gorai 2-Borivali West, Mumbai, 400092,
                    Maharashtra
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======= Contact Map  */}
      <div className="container-fluid gray-bg px-0">
        <div className="row row-eq-height g-0">
          <div className="col-md-12">
            <div className="defoult-form contact-3 pall-50 light-form">
              <div className="section-title">
                <h4 className="title">Send Us Message</h4>
              </div>
              <div className="row">
                <div className="form-group mb-3 col-md-3 col-sm-6">
                  <label className="form-label">Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                    value={custName}
                      onChange={(e) => setCustName(e.target.value)}
                  />{errors.custName && (
                    <span className="error-text">{errors.custName}</span>
                  )}
                </div>
                <div className="form-group mb-3 col-md-3 col-sm-6">
                  <label className="form-label">Email*</label>
                  <input
                    className="form-control"
                    type="email"
                    placeholder="E-mail"
                    value={custEmail}
                      onChange={(e) => setCustEmail(e.target.value)}
                  />
                 {errors.custEmail && (
                    <span className="error-text">{errors.custEmail}</span>
                  )}
                </div>
                <div className="form-group mb-3 col-md-3 col-sm-6">
                  <label className="form-label">Phone*</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Phone Number"
                    value={custContact}
                    onChange={(e) => setCustContact(e.target.value)}
                  />
                 {errors.custContact && (
                    <span className="error-text">{errors.custContact}</span>
                  )}
                </div>
                <div className="form-group mb-3 col-md-3 col-sm-6">
                  <label className="form-label">Subject*</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Subject"
                    value={custSubject}
                    onChange={(e) => setCustSubject(e.target.value)}
                  />
                  {errors.custSubject && (
                    <span className="error-text">{errors.custSubject}</span>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className="form-label">Message*</label>
                  <textarea
                    placeholder="Write Your Message"
                    className="form-control"
                    cols="30"
                    rows="4"
                    value={custMessage}
                  onChange={(e) => setCustMessage(e.target.value)}
                  ></textarea>
                  {errors.custMessage && (
                    <span className="error-text">{errors.custMessage}</span>
                  )}
                </div>
                <div className="form-group">
                  <button
                    className="modal-btn1" onClick={OnSubmit}
                  >
                    <span>Submit Now</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d108063.31537156155!2d72.74304375764284!3d19.164671855518804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b1477498771b%3A0xd20e657cefa22b17!2sLokmanya%20Tilak%20Rd%2C%20Gorai%203%2C%20Borivali%20West%2C%20Mumbai%2C%20Maharashtra%20400091!5e0!3m2!1sen!2sin!4v1697869788793!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div> */}
        </div>
      </div>

      {/* ========== tap =========  */}
      {/* <div className="container">
        <Box
          sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: "background.paper" }}
        >
          <TabContext value={value}>
            <Box
              sx={{
                maxWidth: { xs: 320, sm: 480 },
                bgcolor: "background.paper",
              }}
            >
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Item One" value="1" />
                <Tab label="Item Two" value="2" />
                <Tab label="Item Three" value="3" />
                <Tab label="Item Four" value="4" />
                <Tab label="Item Five" value="5" />
                <Tab label="Item Six" value="6" />
              </TabList>
            </Box>
            <TabPanel value="1">Item One</TabPanel>
            <TabPanel value="2">Item Two</TabPanel>
            <TabPanel value="3">Item Three</TabPanel>
            <TabPanel value="4">Item Four</TabPanel>
            <TabPanel value="5">Item Five</TabPanel>
            <TabPanel value="6">Item Six</TabPanel>
          </TabContext>
        </Box>
      </div> */}
      <Footer />
    </>
  );
};

export default Contact;
