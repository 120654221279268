import React from "react";
import Zoom from "react-reveal/Zoom";
const Items = ({ data }) => {
  return (
    <>
      <div className="row row-cols-lg-3 row-cols-md-2 row-col-1">
        {data.map((value) => {
          const { id, EImage, EventName, ETypes } = value;

          return (
            <>
              <Zoom>
                <div className="col my-3" key={id}>
                  <div className="single-project-style4">
                    <div className="img-holder">
                      <div className="inner">
                        <img src={EImage} alt="Painting Contractor in Borivali" />
                        <div className="overlay-box">
                          <div className="box">
                            <div className="link">
                              <span className="icon-out" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="overlay-content">
                        <div className="title">
                          <span>{ETypes}</span>
                          <h3>{EventName}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Zoom>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Items;
