import React, { useRef, useState, useEffect } from "react";
import "../Css/Home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import "../Css/Common.css";
import "../Css/Home.css";
import CountUp from "react-countup";
import { MetaTags } from "react-meta-tags";
import { BsFillBuildingsFill, BsHouseDoor } from "react-icons/bs";
import {
  AiOutlineFundProjectionScreen,
  AiOutlineFileProtect,
} from "react-icons/ai";
import Zoom from "react-reveal/Zoom";
import Bounce from "react-reveal/Bounce";
import EnquiryModal from "../Components/EnquiryForm";
import Person1 from "../img/testimonal/thum-3.jpg";
import Person2 from "../img/testimonal/thum-4.jpg";
import Person3 from "../img/testimonal/thum-5.jpg";
import Person4 from "../img/testimonal/thum-6.jpg";
import Person5 from "../img/testimonal/thum-7.jpg";
import Person6 from "../img/testimonal/thum-8.jpg";
import Company from "../img/baanner/7.jpg";

const Home = () => {
  // ======== Modal ======
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const banner = document.querySelector(".banner");
    const images = document.querySelectorAll(".image");

    banner.addEventListener("mousemove", (e) => {
      const { clientX, clientY } = e;

      images.forEach((image) => {
        const speed = image.getAttribute("data-speed");
        const x = (window.innerWidth - clientX * speed) / 100;
        const y = (window.innerHeight - clientY * speed) / 100;

        image.style.transform = `translateX(${x}px) translateY(${y}px)`;
      });
    });

    // Reset image positions when mouse leaves the banner
    banner.addEventListener("mouseleave", () => {
      images.forEach((image) => {
        image.style.transform = "translateX(0) translateY(0)";
      });
    });
  }, []);
  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
        <title>Interior Designer in Borivali | Jai Santoshi</title>
        <meta
          name="description"
          content="Looking for an Interior Designer in Borivali to revamp your living space? Our skilled team of designers in Borivali can help you create the home of your dreams."
        />
        <meta
          property="og:title"
          content="Interior Designer in Borivali | Jai Santoshi"
        />
        <meta
          property="og:description"
          content="Looking for an Interior Designer in Borivali to revamp your living space? Our skilled team of designers in Borivali can help you create the home of your dreams."
        />
        <meta
          name="keywords"
          content="Interior Designer in Borivali | Jai Santoshi"
        />
        <link rel="canonical" href="jaisantoshifurnitureworks.in/" />
        <meta name="google-site-verification" content="gRPdG25aiiApMhOHHkpeE6KdvdyjTSPKFESzByKLyHA" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ======= Home Banner ======= */}
      <div className="banner">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="Home-Banner"
        >
          <SwiperSlide>
            <div className="home-banner1">
              <div className="home-Txt">
                <h1 className="d-none">Interior Designer in Borivali</h1>
                <Zoom left>
                  <h2>Civil Contractors</h2>
                </Zoom>
                {/* <p>
                Crafting enduring spaces that stand as a testament to quality and innovation, Your trusted civil contracting partner.
                </p> */}
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="home-banner2">
              <div className="home-Txt">
                <Zoom left>
                  <h2>Interior Decorators</h2>
                </Zoom>
                {/* <p>
                Experience the art of design we where innovation and elegance converge to shape your dream spaces.
                </p> */}
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="home-banner3">
              <div className="home-Txt">
                <h2>Flooring Contractors</h2>
                {/* <p>
                Where every step is a work of art. Elevate your space with our expert flooring solutions
                </p> */}
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* ======= About Section ===== */}
      <section className="our-sercive page-section-ptb">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <Zoom left>
                <img
                  className="img-fluid"
                  src={Company}
                  alt="about jai santoshi"
                />
              </Zoom>
            </div>
            <div className="col-lg-6">
              <div className="content-block pl-10">
                <div className="section-title">
                  <h2 className="title">About Us</h2>
                </div>
                <p>
                  Welcome to the Jai santoshi furniture works, a civil
                  contractor in borivali . Our work focuses on luxury interiors
                  and architecture. We take part in many projects, from home
                  projects to office and hotel projects. Jai Santoshi furniture
                  works has experience in turning your design dreams into
                  reality. Interior design is the art and science of improving
                  the interior of a building to create a healthy and beautiful
                  environment for the people who use the space.
                </p>
                <p>
                  Our civil contractor in borivali, uses a variety of designs
                  and patterns to create beautiful and functional spaces. All
                  our products and services are recognized by our customers for
                  their durability, beautiful designs, beautiful appearance,
                  flawless surfaces and smooth edges. These products are
                  available in various designs, shapes and sizes to meet the
                  diverse needs of customers.
                </p>
                {/* <div className="row mt-30">
                  <div className="col-sm-6 mb-4 mb-sm-0">
                    <div className="feature-box left_bor left_pos ex-small mb-0">
                    <i class="fa-solid fa-building"></i>
                      <h3 className="title">Good Sale &amp; Marketing</h3>
                      <p>
                        Eiusmod tempor incididunt ut labore et dolore Ut enim ad
                        minim veniam quis
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="feature-box left_bor left_pos ex-small mb-0">
                    <i class="fas fa-chart-bar"></i>
                      <h3 className="title">Virtual Construction (BIM)</h3>
                      <p>
                        It has survived not only five centuries, but also the
                        leap into electronic
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* =========== button ========== */}
      <div className="action-box small yellow-bg">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-8 text-start">
              <h4 className="m-0">
                Jai Santoshi Furniture Works Contractors &amp; Construction
              </h4>
            </div>
            <div className="col-md-4 text-start text-md-end">
              <div className="Enquiry">
                <div className="modal-btn" onClick={() => setModalShow(true)}>
                  Get A Enquiry
                </div>

                <EnquiryModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==== Company ========= */}
      <section
        className="dark-bg page-section-ptb dark-bg bg fixed text-white gradient-overlay"
        style={{
          backgroundImage: `linear-gradient(
            120deg,
            rgba(0, 0, 0, 0.4) 0%,
            rgba(0, 0, 0, 0.4) 100%
          ),url(${Company})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="section-title">
                <h2 className="title title2">
                  The Best <label>Compnay</label> Ever
                </h2>
              </div>
              <div className="row mt-50">
                <div className="col-md-6">
                  <Zoom>
                    <div className="feature-box left_pos small">
                      <i>
                        <BsFillBuildingsFill />
                      </i>
                      <h3 className="title">Project Buildings</h3>
                      <p>
                        From initial concept to design, installation and
                        completion.
                      </p>
                    </div>
                  </Zoom>
                </div>
                <div className="col-md-6">
                  <Zoom>
                    <div className="feature-box left_pos small">
                      <i>
                        <AiOutlineFileProtect />
                      </i>
                      <h3 className="title">Home Maintenance</h3>
                      <p>We are dedicated to managing your dream home</p>
                    </div>
                  </Zoom>
                </div>
                <div className="col-md-6 mb-4 mb-md-0 pb-3 pb-md-0">
                  <Zoom>
                    <div className="feature-box left_pos small mb-0">
                      <i>
                        <BsHouseDoor />
                      </i>
                      <h3 className="title">Value Engineering</h3>
                      <p>We design and work to offer you the best</p>
                    </div>
                  </Zoom>
                </div>
                <div className="col-md-6">
                  <Zoom>
                    <div className="feature-box left_pos small mb-0">
                      <i>
                        <AiOutlineFundProjectionScreen />
                      </i>
                      <i className="glyph-icon flaticon-projection-screen-with-bar-chart" />
                      <h3 className="title">Project Management</h3>
                      <p>
                        We can enable you to create any type of home, from
                        traditional to modern for fusion architecture.
                      </p>
                    </div>
                  </Zoom>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============ Services ============= */}
      <section className="our-sercive1 page-section-pt">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title text-center">
                <h2 className="title">Why Choose us</h2>
                <p>
                  We believe in making our designs aesthetic and fully
                  utilitarian
                </p>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="feature-box line">
                <i className="glyph-icon flaticon-people-10" />
                <Bounce>
                  <h3 className="title">Safety</h3>
                </Bounce>
                <p>We committed to design with all safety</p>
              </div>
              <div className="feature-box line">
                <i className="glyph-icon flaticon-trophy" />
                <Bounce>
                  <h3 className="title">Best Quality</h3>
                </Bounce>
                <p>We provide on-time delivery without compromising quality.</p>
              </div>
              <div className="feature-box line">
                <i className="glyph-icon flaticon-drawing-1" />
                <Bounce>
                  <h3 className="title">Sustanability</h3>
                </Bounce>
                <p>
                  We attach importance to durability and quality by using
                  branded products.
                </p>
              </div>
              <div className="feature-box line">
                <i className="glyph-icon flaticon-people-10" />
                <Bounce>
                  <h3 className="title">Community</h3>
                </Bounce>
                <p>
                  Our goal is to exceed customers' expectations by providing an
                  environment that reflects their needs
                </p>
              </div>
              <div className="feature-box line">
                <i className="glyph-icon flaticon-projection-screen-with-bar-chart" />
                <Bounce>
                  <h3 className="title">Strategy</h3>
                </Bounce>
                <p>
                  We use energy to express architecture and space, space and
                  form, color and materials, business designs, relationships
                  with justice.
                </p>
              </div>
              <div className="feature-box line">
                <i className="glyph-icon flaticon-interface-5" />
                <Bounce>
                  <h3 className="title">Integrity</h3>
                </Bounce>
                <p>
                  Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                  amet, consectetur, adipisci velit, sed quia non numquam eius
                  modi tempora incidunt.
                </p>
              </div>
            </div>
          </div>
          <div className="text-center mt-40">
            <img
              className="img-fluid"
              src="http://themes.potenzaglobalsolutions.com/html/constro/images/element/01.png"
              alt="intergity"
            />
          </div>
        </div>
      </section>

      {/* ============ Services Testimonal ========= */}
      <section className="page-section-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12">
              <div className="section-title text-center">
                <h2 className="title">Our Client Say</h2>
              </div>
            </div>
            <Zoom left>
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                className="serviceTestimonal"
              >
                <SwiperSlide>
                  <div className="owl-item">
                    <div className="item">
                      <div className="testimonial">
                        <div className="testimonial-avatar">
                        <img
                            alt="testimonal"
                            src={Person6}
                          />
                        </div>
                        <div className="testimonial-info">
                          They completed all my work in a short time period!
                          Very good.
                        </div>
                        <div className="author-info">
                          {" "}
                          <strong>Sonali Bhasin -</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="owl-item">
                    <div className="item">
                      <div className="testimonial">
                        <div className="testimonial-avatar">
                          <img
                            alt="testimonal"
                            src={Person1}
                          />
                        </div>
                        <div className="testimonial-info">
                          Good work was done by the team at comparatively lesser
                          rates.
                        </div>
                        <div className="author-info">
                          {" "}
                          <strong>Rohan Sen -</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="owl-item">
                    <div className="item">
                      <div className="testimonial">
                        <div className="testimonial-avatar">
                        <img
                            alt="testimonal"
                            src={Person5}
                          />
                        </div>
                        <div className="testimonial-info">
                          They are very much aware of the current property
                          trends. Will surely recommend others.
                        </div>
                        <div className="author-info">
                          {" "}
                          <strong>Neha Mishra -</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="owl-item">
                    <div className="item">
                      <div className="testimonial">
                        <div className="testimonial-avatar">
                        <img
                            alt="testimonal"
                            src={Person2}
                          />
                        </div>
                        <div className="testimonial-info">
                          The plumber provided excellent service, fixing the
                          issue promptly and professionally.
                        </div>
                        <div className="author-info">
                          {" "}
                          <strong>Siddesh khantria -</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="owl-item">
                    <div className="item">
                      <div className="testimonial">
                        <div className="testimonial-avatar">
                        <img
                            alt="testimonal"
                            src={Person3}
                          />
                        </div>
                        <div className="testimonial-info">
                          The interior design of the space is stunning, creating
                          a Good and inviting atmosphere.
                        </div>
                        <div className="author-info">
                          {" "}
                          <strong>Ayuesh Chawan -</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="owl-item">
                    <div className="item">
                      <div className="testimonial">
                        <div className="testimonial-avatar">
                        <img
                            alt="testimonal"
                            src={Person4}
                          />
                        </div>
                        <div className="testimonial-info">
                          Her painter transformed our space with skillful
                          artistry, turning our vision into a beautiful reality
                        </div>
                        <div className="author-info">
                          {" "}
                          <strong>Diksha Rajput -</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </Zoom>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
