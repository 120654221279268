import React from "react";
import "../Css/Footer.css";
import { Link } from "react-router-dom";
import Logo from "../img/logo.jpeg";

const Footer = () => {
  return (
    <>
      <footer className="footer dark-bg page-section-pt">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 order-lg-1 order-sm-1 order-1">
              <div className="footer-about">
                {/* <div className="section-title">
                  <h4 className="title">About Constro</h4>
                </div> */}
                <span className="mb-20 d-block">
                  <Link to="/">
                    {" "}
                    <img id="logo-footer" src={Logo} width={180} alt="logo" />
                  </Link>
                </span>
                <p>
                  Welcome to the Jai santoshi furniture works, a civil
                  contractor in borivali . Our work focuses on luxury interiors
                  and architecture.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 order-lg-2 order-sm-4 order-2">
              <div className="footer-link">
                <div className="section-title">
                  <h4 className="title">Our Support</h4>
                </div>
                <ul className="list-mark">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/civil-contractor-borivali">About</Link>
                  </li>
                  <li>
                    <Link to="/false-ceiling-contractor-borivali">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/painting-contractor-borivali">Project</Link>
                  </li>
                  <li>
                    <Link to="/flooring-contractor-borivali-mumbai">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 order-lg-3 order-sm-3 order-3">
              <div className="footer-link">
                <div className="section-title">
                  <h4 className="title">Our Services</h4>
                </div>
                <ul className="list-mark">
                  <li>
                    <Link to="/false-ceiling-contractor-borivali">
                      Interior Designing
                    </Link>
                  </li>
                  <li>
                    <Link to="/false-ceiling-contractor-borivali">
                      Civil Contractors
                    </Link>
                  </li>
                  <li>
                    <Link to="/false-ceiling-contractor-borivali">
                      Contractors
                    </Link>
                  </li>
                  <li>
                    <Link to="/false-ceiling-contractor-borivali">
                      Flooring Contractors
                    </Link>
                  </li>
                  <li>
                    <Link to="/false-ceiling-contractor-borivali">
                      Painting Contractors
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 order-lg-4 order-sm-2 order-4">
              <div className="footer-address">
                <div className="section-title">
                  <h4 className="title">Get in Touch</h4>
                </div>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <i className="fa fa-map-marker" />{" "}
                    <span>
                      Ganesh Chs Ltd Shop No1 ,2,Opp Pillai School,near Ajra
                      Bank,Gorai Road, Gorai 2-Borivali West, Mumbai, 400092,
                      Maharashtra
                    </span>
                  </li>
                  <li className="list-inline-item">
                    <i className="fa fa-phone" />{" "}
                    <span>
                      {" "}
                      <a href="tel:+91-9820141690">+91-9820141690</a> / 
                      <a href="tel:+91-9372686073">+91-9372686073</a>
                    </span>
                  </li>
                  <li className="list-inline-item">
                    <i className="fa fa-envelope" />{" "}
                    <span>
                      <a href="jsfwl95@gmail.com">jsfwl95@gmail.com</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="Footer_copyRight">
          <div className="container">
            <div className="row">
              <div class="col-md-6">
                <div class="copyright">
                  <p>
                    Copyright © 2023 All Rights Reserved{" "}
                    <span>JSFW</span>{" "}
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="develope-by">
                  <p>
                    Designed by
                    <a href="https://skdm.in/">
                      {" "}
                      Shree Krishna Digital Marketing
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
