import React, { useState, useEffect } from "react";
import "../Css/NavBar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiPhoneCall, FiClock } from "react-icons/fi";
import logo from "..//img/logo.jpeg";
import ServiceData from "./ServiceData";
import {
  GrFacebookOption,
  GrInstagram,
  GrLinkedinOption,
  GrGoogle,
} from "react-icons/gr";
import { RiWhatsappFill } from "react-icons/ri";
import { AiOutlineYoutube } from "react-icons/ai";
import { IoCall } from "react-icons/io5";

const NavBar = () => {
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  // popup Modal
  const [modalShow, setModalShow] = React.useState(false);

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className="nav-bar-component">
        <div className="header">
          <div className="container">
            <div className="row">
              <div className="col-4">
              <div className="head-Num">
              <a href="mailto:jsfwl95@gmail.com">jsfwl95@gmail.com</a>
              </div>
              </div>
              <div className="col-4">
                <div className="row">
                  <div className="col border-start">
                    <div className="Social-icon">
                      <div className="rightIcon-list">
                        <a href="https://www.facebook.com/profile.php?id=61552456442722" target="blank">
                          <i class="facebook">
                            <GrFacebookOption />
                          </i>
                        </a>
                      </div>
                      <div className="rightIcon-list">
                        <a
                          href="https://www.instagram.com/jaisantoshifurnitue/"
                          target="blank"
                        >
                          <i class="instagram">
                            <GrInstagram />
                          </i>
                        </a>
                      </div>
                      {/* <div className="rightIcon-list">
                      <a href="https://www.youtube.com/" target="blank">
                            <i class="youtube-play">
                              <AiOutlineYoutube />
                            </i>
                          </a>
                      </div> */}
                      <div className="rightIcon-list">
                        <a
                          href="https://wa.me/+919820141690?text="
                          target="blank"
                        >
                          <i class="whatsapp">
                            <RiWhatsappFill />
                          </i>
                        </a>
                      </div>
                      <div className="rightIcon-list">
                        <a href="tel:+919820141690" target="blank">
                          <i class="calling">
                            {" "}
                            <IoCall />
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 border-start">
              <div className="head-Num">
              <a href="tel:+91-9820141690">+91-9820141690 / </a>
              <a href="tel:+91-9372686073">  +91-9372686073</a>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="NavSection">
          <div className="container-fluid">
            <Navbar
              collapseOnSelect
              expand="lg"
              className={color ? "NavBar NavBar-bg" : "NavBar"}
            >
              <Container>
                <Navbar.Brand href="/">
                  <img src={logo} alt="" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse
                  id="responsive-navbar-nav"
                  className="justify-content-end"
                >
                  <Nav>
                    <Nav.Link href="/">
                      <Link to="/">Home</Link>
                    </Nav.Link>
                    <Nav.Link href="/civil-contractor-borivali">
                      <Link to="/civil-contractor-borivali">About</Link>
                    </Nav.Link>
                    <Nav.Link href="/false-ceiling-contractor-borivali">
                      <Link to="/false-ceiling-contractor-borivali">
                        Services
                      </Link>
                    </Nav.Link>
                    <Nav.Link href="/painting-contractor-borivali">
                      <Link to="/painting-contractor-borivali">Project</Link>
                    </Nav.Link>
                    <Nav.Link href="/flooring-contractor-borivali-mumbai">
                      <Link to="/flooring-contractor-borivali-mumbai">
                        Contact
                      </Link>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
