import "./App.css";
import NavBar from "./Components/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import Services from "./Pages/Services";
import ServiceData from "./Components/ServiceData";
import Projects from "./Pages/Projects";
import ServicesView from "./Pages/ServicesView";
import ErrorPage from './Components/ErrorPage';
import RightIcon from './Components/RightSideIcon';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <RightIcon/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/civil-contractor-borivali" element={<About />} />
          <Route path="/false-ceiling-contractor-borivali" element={<Services />} />
          {ServiceData.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.Slugs}
              element={<ServicesView />}
            />
          ))}
          <Route path="/painting-contractor-borivali" element={<Projects />} />
          <Route path="/flooring-contractor-borivali-mumbai" element={<Contact />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
