import React from "react";
import "../Css/RightSideIcon.css";
import { GrFacebookOption, GrInstagram } from "react-icons/gr";
import { RiWhatsappFill } from "react-icons/ri";
import { AiOutlineYoutube } from "react-icons/ai";
import { IoCall } from "react-icons/io5";

const RightSideIcon = () => {
  return (
    <>
      <div className="Right_Icon d-md-none d-block">
        <ul>
          <li>
            <a href="https://www.facebook.com/profile.php?id=61552456442722" target="blank">
              <i class="facebook">
                <GrFacebookOption />
              </i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/jaisantoshifurnitue/" target="blank">
              <i class="instagram">
                <GrInstagram />
              </i>
            </a>
          </li>
          <li>
            <a href="https://wa.me/+919820141690?text=" target="blank">
              <i class="whatsapp">
                <RiWhatsappFill />
              </i>
            </a>
          </li>
          <li>
            <a href="tel:+919820141690" target="blank">
              <i class="calling">
                {" "}
                <IoCall />
              </i>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default RightSideIcon;
