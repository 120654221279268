import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import "../Css/Services.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Footer from "../Components/Footer";
import ServiceData from "../Components/ServiceData";
import { MetaTags } from "react-meta-tags";
import {
  AiOutlineFundProjectionScreen,
  AiOutlineFileProtect,
} from "react-icons/ai";
import { BsFillBuildingsFill, BsHouseDoor } from "react-icons/bs";

import Button from "react-bootstrap/Button";
import Enquiry from "../Components/EnquiryForm";
import { Link } from "react-router-dom";

import Person1 from "../img/testimonal/thum-3.jpg";
import Person2 from "../img/testimonal/thum-4.jpg";
import Person3 from "../img/testimonal/thum-5.jpg";
import Person4 from "../img/testimonal/thum-6.jpg";
import Person5 from "../img/testimonal/thum-7.jpg";
import Person6 from "../img/testimonal/thum-8.jpg";
import ServiceImg from "../img/residental/sandeep1.jpg";

const Services = () => {
  // popup Modal
  const [modalShow, setModalShow] = React.useState(false);

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
        <title>False Ceiling Contractor in Borivali | Jai Santoshi</title>
        <meta name="description" content="Looking for a reliable False Ceiling Contractor in Borivali? Look no further! Our team of experienced professionals specializes in false ceiling installations," />
        <meta property="og:title" content="False Ceiling Contractor in Borivali | Jai Santoshi" />
        <meta property="og:description" content="Looking for a reliable False Ceiling Contractor in Borivali? Look no further! Our team of experienced professionals specializes in false ceiling installations," />
        <meta name="keywords" content="False Ceiling Contractor in Borivali" />
        <link rel="canonical" href="jaisantoshifurnitureworks.in/false-ceiling-contractor-borivali" />
        <meta name="google-site-verification" content="gRPdG25aiiApMhOHHkpeE6KdvdyjTSPKFESzByKLyHA" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ============ Serrvices Banner ========== */}
      <section className="services-banner">
        <div className="container">
          <div className="services-Txt">
          <h1 className="d-none">False Ceiling Contractor in Borivali</h1>
            <h2>Services</h2>
            <ul className="page-breadcrumb">
              <li>
                <Link to="/">
                  <i className="fa fa-home" /> Home
                </Link>{" "}
                <i className="fa fa-angle-double-right" />
              </li>
              <li>
                <Link to="/false-ceiling-contractor-borivali">pages</Link>{" "}
                <i className="fa fa-angle-double-right" />
              </li>
              <li>
                <span>Services</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* =========== Services Section Page ======== */}
      <section className="our-sercive page-section-ptb">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <img
                alt="service image"
                src="http://themes.potenzaglobalsolutions.com/html/constro/images/about/04.jpg"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6">
              <div className="section-title">
                <h2 className="title">We are creating Your dream</h2>
              </div>
              <p>
              Turning Visions into Reality, One Project at a Time. Crafting Your Dreams, Building Your Future.
              </p>
              <div className="row services clearfix mt-30">
                <div className="col-sm-6">
                  <div className="feature-box left_pos small">
                    <i>
                      <BsFillBuildingsFill />
                    </i>
                    <h3 className="title">Project Buildings</h3>
                    <p>
                      From initial concept to design, installation and
                      completion.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="feature-box left_pos small">
                    <i>
                      <AiOutlineFileProtect />
                    </i>
                    <h3 className="title">Home Maintenance</h3>
                    <p>We are dedicated to managing your dream home</p>
                  </div>
                </div>
                <div className="col-sm-6 mb-4 mb-sm-0 pb-3 pb-sm-0">
                  <div className="feature-box left_pos small mb-0">
                    <i>
                      <BsHouseDoor />
                    </i>
                    <h3 className="title">Value Engineering</h3>
                    <p>We design and work to offer you the best</p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="feature-box left_pos small mb-0">
                    <i>
                      <AiOutlineFundProjectionScreen />
                    </i>
                    <h3 className="title">Project Management</h3>
                    <p> We can enable you to create any type of home.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========== Services ATTENTION! ======== */}
      <section
        className="action-box action-icon action-box-2 bg fixed text-white bg-overlay-black-80"
        style={{
          backgroundImage:
            "url(http://themes.potenzaglobalsolutions.com/html/constro/images/bg/bg-2.jpg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-9 text-start">
              <div className="left">
                <i className="glyph-icon flaticon-sign" />
                <h2 className="text-uppercase pb-10">Attention!</h2>
                <h5>
                  Looking for best partner for your next construction works Jai Santoshi Furniture Works Is Best
                </h5>
              </div>
            </div>
            <div className="col-md-3 mt-20 text-start text-md-end">
              {" "}
              <a
                href="tel:+91-9820141690"
                className="modal-btn1"
              >
                <span>Contact Us Now</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* =========== Tap Services Offer ============ */}
      <section className="page-section-ptb">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="section-title text-center">
                <h2 className="title">Our Services</h2>
                <p>
                  Construction Project Management (CPM) is the overall planning,
                  coordination, and control of a project from beginning to
                  completion. CPM is aimed at meeting a client's requirement in
                  order to produce a functionally and financially viable
                  project.
                </p>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="tab tab-bor clearfix">
                <ul className="nav nav-tabs" role="tablist">
                  {ServiceData.map((ele) => {
                    const { Header, Slugs, isDefault } = ele;
                    const tabClass = isDefault ? "nav-link active" : "nav-link";
                    return (
                      <li key={Slugs}>
                        <a
                          href={`#${Slugs}`}
                          className={tabClass}
                          data-bs-toggle="tab"
                        >
                          <i className="glyph-icon flaticon-buildings-1" />
                          <span>{Header}</span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <div className="tab-content">
                  {ServiceData.map((ele) => {
                    const { Header, Slugs, SercicesPara1, SercicesPara2 } = ele;
                    const tabClass = ele.isDefault
                      ? "tab-pane fade active show"
                      : "tab-pane";
                    return (
                      <div
                        key={Slugs}
                        role="tabpanel"
                        className={tabClass}
                        id={Slugs}
                      >
                        <h3 className="title mb-20">{Header}</h3>
                        <p>{SercicesPara1}</p>
                        <p>{SercicesPara2}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =============== Our Services Heading ========== */}
      {/* <div
        className="page-section-ptb pb-40 text-white bg fixed bg-overlay-black-70"
        style={{
          backgroundImage:
            "url(http://themes.potenzaglobalsolutions.com/html/constro/images/bg/bg-4.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 text-white">
              <div className="section-title text-start">
                <h2 className="title">Our Services</h2>
                <p>
                  It has survived not only five centuries adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="feature-box left_pos small">
                    {" "}
                    <i className="glyph-icon flaticon-construction-14" />
                    <h3 className="title">Heading 1</h3>
                    <p>
                      Labore et dolore magnam aliquam quaerat voluptatem minim
                      veniam quis nostrud.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="feature-box left_pos small">
                    {" "}
                    <i className="glyph-icon flaticon-construction-34" />
                    <h3 className="title">Heading 2</h3>
                    <p>
                      Dolore Ut enim ad minim veniam quis nostrud eiusmod tempor
                      incididunt ut labore.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="feature-box left_pos small">
                    {" "}
                    <i className="glyph-icon flaticon-drawing" />
                    <h3 className="title">Heading 3</h3>
                    <p>
                      CPM is aimed at meeting a client's requirement in order to
                      produce.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="feature-box left_pos small">
                    {" "}
                    <i className="glyph-icon flaticon-drawing-1" />
                    <h3 className="title">Heading 4</h3>
                    <p>
                      Nor again is there anyone who loves or pursues or desires
                      to obtain pain.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 mb-4 mb-sm-0 pb-3 pb-sm-0">
                  <div className="feature-box left_pos small mb-0">
                    {" "}
                    <i className="glyph-icon flaticon-drawing" />
                    <h3 className="title">Heading 5</h3>
                    <p>
                      Eiusmod tempor incididunt ut labore et dolore Ut enim ad
                      minim veniam quis nostrud.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="feature-box left_pos small mb-0">
                    {" "}
                    <i className="glyph-icon flaticon-drawing-1" />
                    <h3 className="title">Heading 6</h3>
                    <p>
                      Enim ad minim veniam quis nostrud eSed do eiusmod tempor
                      incididunt ut labor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* ============ Services Testimonal ========= */}
      <section className="service-testimonal mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12">
              <div className="section-title text-center">
                <h2 className="title">Our Client Say</h2>
                {/* <p>
                  Construction Project Management (CPM) is the overall planning,
                  coordination, and control of a project from beginning to
                  completion. CPM is aimed at meeting a client's requirement in
                  order to produce a functionally and financially viable
                  project.
                </p> */}
              </div>
            </div>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                className="serviceTestimonal"
              >
                <SwiperSlide>
                  <div className="owl-item">
                    <div className="item">
                      <div className="testimonial">
                        <div className="testimonial-avatar">
                        <img
                            alt="testimonal"
                            src={Person6}
                          />
                        </div>
                        <div className="testimonial-info">
                          They completed all my work in a short time period!
                          Very good.
                        </div>
                        <div className="author-info">
                          {" "}
                          <strong>Sonali Bhasin -</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="owl-item">
                    <div className="item">
                      <div className="testimonial">
                        <div className="testimonial-avatar">
                          <img
                            alt="testimonal"
                            src={Person1}
                          />
                        </div>
                        <div className="testimonial-info">
                          Good work was done by the team at comparatively lesser
                          rates.
                        </div>
                        <div className="author-info">
                          {" "}
                          <strong>Rohan Sen -</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="owl-item">
                    <div className="item">
                      <div className="testimonial">
                        <div className="testimonial-avatar">
                        <img
                            alt="testimonal"
                            src={Person5}
                          />
                        </div>
                        <div className="testimonial-info">
                          They are very much aware of the current property
                          trends. Will surely recommend others.
                        </div>
                        <div className="author-info">
                          {" "}
                          <strong>Neha Mishra -</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="owl-item">
                    <div className="item">
                      <div className="testimonial">
                        <div className="testimonial-avatar">
                        <img
                            alt="testimonal"
                            src={Person2}
                          />
                        </div>
                        <div className="testimonial-info">
                          The plumber provided excellent service, fixing the
                          issue promptly and professionally.
                        </div>
                        <div className="author-info">
                          {" "}
                          <strong>Siddesh khantria -</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="owl-item">
                    <div className="item">
                      <div className="testimonial">
                        <div className="testimonial-avatar">
                        <img
                            alt="testimonal"
                            src={Person3}
                          />
                        </div>
                        <div className="testimonial-info">
                          The interior design of the space is stunning, creating
                          a Good and inviting atmosphere.
                        </div>
                        <div className="author-info">
                          {" "}
                          <strong>Ayuesh Chawan -</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="owl-item">
                    <div className="item">
                      <div className="testimonial">
                        <div className="testimonial-avatar">
                        <img
                            alt="testimonal"
                            src={Person4}
                          />
                        </div>
                        <div className="testimonial-info">
                          Her painter transformed our space with skillful
                          artistry, turning our vision into a beautiful reality
                        </div>
                        <div className="author-info">
                          {" "}
                          <strong>Diksha Rajput -</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper> 
          </div>
        </div>
      </section>
      {/* ========== Services Contact Button ============ */}
      <section className="action-box small yellow-bg">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-8 text-start">
              <h4 className="m-0">
              Jai Santoshi Furniture Works
                Contractors &amp; Construction
              </h4>
            </div>
            <div className="col-md-4 text-start text-md-end">
              <div
                className="modal-btn"
                onClick={() => setModalShow(true)}
              >
                <span>GET A QUOTE</span>
              </div>
              <Enquiry show={modalShow} onHide={() => setModalShow(false)} />
            </div>
          </div>
        </div>
      </section>

      {/* ========== */}

      <Footer />
    </>
  );
};

export default Services;
