import React, { useState, useLayoutEffect } from "react";
import "../Css/Project.css";
import GalleryData from "../Components/GalleryData";
import { Link, useLocation, useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import Tabs from "./Tabs";
import Items from "./Items";
import List1 from "../img/list1.jpg";
import List2 from "../img/list2.jpg";
import { MetaTags } from "react-meta-tags";

const Projects = ({}) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const [data, setData] = useState(GalleryData);

  // Store Category in CategoryData
  const categoryData = GalleryData.map((value) => {
    return value.Category;
  });
  ////////////

  const tabsData = ["all", ...new Set(categoryData)];

  // for all categoryData set
  const filterCategory = (Category) => {
    if (Category == "all") {
      setData(GalleryData);
      return;
    }
    const filteredData = GalleryData.filter((value) => {
      return value.Category == Category;
    });

    setData(filteredData);
  };

  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
        <title>Painting Contractor in Borivali | Jai Santoshi</title>
        <meta name="description" content="Experience top-tier construction mastery with the premier Painting Contractor in Borivali. Elevate your visions with our expert touch and finesse." />
        <meta property="og:title" content="Painting Contractor in Borivali | Jai Santoshi" />
        <meta property="og:description" content="Experience top-tier construction mastery with the premier Painting Contractor in Borivali. Elevate your visions with our expert touch and finesse." />
        <meta name="keywords" content="Painting Contractor in Borivali" />
        <link rel="canonical" href="jaisantoshifurnitureworks.in/painting-contractor-borivali" />
        <meta name="google-site-verification" content="gRPdG25aiiApMhOHHkpeE6KdvdyjTSPKFESzByKLyHA" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ========= poject Banner ========== */}
      <section className="project-banner">
        <div className="container">
          <div className="project-Txt">
          <h1 className="d-none">Painting Contractor in Borivali</h1>
            <h2>Project</h2>
            <ul className="page-breadcrumb">
              <li>
                <Link to="/">
                  <i className="fa fa-home" /> Home
                </Link>{" "}
                <i className="fa fa-angle-double-right" />
              </li>
              <li>
                <Link to="/painting-contractor-borivali">pages</Link>{" "}
                <i className="fa fa-angle-double-right" />
              </li>
              <li>
                <span>Project</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* ========== Project Gallery ====== */}
      <div className="Gallery-Section-2">
        <div className="Gallery-Card">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Tabs filterCategory={filterCategory} tabsData={tabsData} />
                <Items data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ========== Project List ====== */}
      <section className="project-List mt-5 mb-5">
        <div className="container">
          <div className="col-sm-12">
            <div className="section-title text-center">
              <h2 className="title">List Of Work</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="list-Img">
                <img src={List1} alt="completed project" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="list-Img">
                <img src={List2} alt="completed project" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Projects;
